import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const askMarketingQuestion = api((question, token, email) =>
    query(
        gql`
            query AskMarketingQuestion($question: String!, $email: String, $token: String!) {
                askMarketingQuestion(question: $question, email: $email, token: $token)
            }
        `,
        { question, email, token },
        { returns: "askMarketingQuestion" }
    )
)
