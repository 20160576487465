import { useEffect, useState } from "react"
import { Box, LinearProgress, Stack, Typography } from "@mui/material"

export function ProgressAI() {
    const [value, setValue] = useState(0)
    useEffect(() => {
        const timer = setInterval(() => setValue((a) => a + 0.5), 500)
        return () => clearInterval(timer)
    }, [])
    return (
        <Box sx={{ my: 4, borderRadius: 1, p: 2, border: "1px solid", borderColor: "primary.lighter" }}>
            <Stack spacing={2}>
                <Typography variant="subtitle2">Thinking</Typography>
                <LinearProgress variant="determinate" value={(value * 100) / 120} />
                <Typography variant="body2">Please wait...</Typography>
            </Stack>
        </Box>
    )
}
