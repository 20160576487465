import { register } from "lib/routes/register"
import { RecaptchaGuard } from "lib/recaptcha-guard"
import { SearchForm } from "routes/plugins/marketing-ai-search/search-form"

register(
    "/ai-search",
    <RecaptchaGuard>
        <SearchForm />
    </RecaptchaGuard>
)
