import html2pdf from "html2pdf.js"

export function convertToPdf(htmlString, filename = "document.pdf") {
    // Create a hidden container for the HTML content

    const options = {
        margin: 0.5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "A4", orientation: "portrait" },
    }

    // Delay to ensure the container is fully rendered.
    setTimeout(() => {
        html2pdf()
            .set(options)
            .from(htmlString)
            .to("pdf")
            .get("pdf")
            .then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                // jsPDF coordinates are in inches (per our unit setting)
                const pageWidth = 8.5 // Letter width in inches
                const bottomMargin = 0.5 // same as the margin option
                const yPos = 11.7 - bottomMargin + 0.2 // slightly above the bottom edge

                pdf.setFontSize(10)
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    // Center the page number on the page
                    pdf.text(`Page ${i} of ${totalPages}`, pageWidth / 2, yPos, { align: "center" })
                }
                return pdf
            })
            .outputPdf("blob")
            .then((pdfBlob) => {
                const url = URL.createObjectURL(pdfBlob)
                const link = document.createElement("a")
                link.href = url
                link.download = filename
                link.click()
                URL.revokeObjectURL(url)
            })
            .catch((error) => {
                console.error("Error generating PDF:", error)
            })
    }, 200)
}

export function exportToPdf(element, filename = "document.pdf") {
    // Create a hidden container for the HTML content
    const options = {
        margin: 0.5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    }

    // Delay to ensure the container is fully rendered.
    setTimeout(() => {
        html2pdf()
            .set(options)
            .from(element)
            .outputPdf("blob")
            .then((pdfBlob) => {
                const url = URL.createObjectURL(pdfBlob)
                const link = document.createElement("a")
                link.href = url
                link.download = filename
                link.click()
                URL.revokeObjectURL(url)
            })
            .catch((error) => {
                console.error("Error generating PDF:", error)
            })
    }, 0)
}
