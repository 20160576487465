import React, { useEffect, useState } from "react"
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { Bound } from "lib/@components/binding/Bound"

export function RecaptchaGuard({ children }) {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LdfxeMqAAAAAOIYTxgTxKQnjMJ_v-9-h2q6aGxH">
            <InnerGuard>{children}</InnerGuard>
        </GoogleReCaptchaProvider>
    )
}

function InnerGuard({ children }) {
    const [isVerified, setIsVerified] = useState(false)
    const [token, setToken] = useState(null)
    const { executeRecaptcha } = useGoogleReCaptcha()

    useEffect(runRecaptcha, [executeRecaptcha])

    return (
        <Bound recaptcha={token}>
            {!isVerified ? (
                <div style={{ textAlign: "center", padding: "20px" }}>
                    <h2>Verifying...</h2>
                </div>
            ) : (
                children
            )}
        </Bound>
    )

    function runRecaptcha() {
        if (!executeRecaptcha) {
            return
        }
        executeRecaptcha("homepage").then(function (token) {
            handleVerification(token)
        })
    }

    function handleVerification(token) {
        if (token) {
            setIsVerified(true)
            setToken(token)
        }
    }
}
