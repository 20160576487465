const { createEvent } = require("library/local-events")

const BasketItemAdded = createEvent("BasketItemAdded")
const Uncache = createEvent("Uncache")
const LocalDatabaseEvents = createEvent("Database")
const LocalDatabaseWillGet = LocalDatabaseEvents("WillGet")
const LocalDatabasePut = LocalDatabaseEvents("Put")
const LocalDatabaseGet = LocalDatabaseEvents("Get")
const LocalDatabaseUpdate = LocalDatabaseEvents("Update")
const LocalUpdateTable = LocalDatabaseEvents("UpdateTable")
const RecordUpdate = createEvent("Record.Update")

const Table = createEvent("Table")
const AddRow = Table("AddRow")
const DeleteRow = Table("DeleteRow")
const TableUpdate = Table("Update")
const TableFlushCache = Table("FlushCache")

const ClientChanged = createEvent("ClientChanged")
const Authorization = createEvent("Authorization", { isAsync: true })
const VerifiedUser = createEvent("VerifiedUser", { isAsync: true })
const ValidateToken = createEvent("ValidateToken", { isAsync: true })
const DecorateUser = createEvent("DecorateUser", { isAsync: true })
const EnsureClient = createEvent("EnsureClient", { isAsync: true })
const RecycleItem = createEvent("RecycleItem", { isAsync: true })
const RestoreItem = createEvent("RestoreItem", { isAsync: true, extract: (v) => v[0].handleFile })
const ScheduleFilter = createEvent("ScheduleFilter", { isAsync: true, extract: (v) => v[0].rows })
const ValidateWebhookRegistration = createEvent("ValidateWebhookRegistration", {
    isAsync: true,
    extract: (v) => v[0].isValid,
})
const WebhookRegistered = ValidateWebhookRegistration("WebhookRegistered")
const SendWebhooks = createEvent("SendWebhooks", { isAsync: true })

exports.ClientChanged = ClientChanged
exports.ScheduleFilter = ScheduleFilter
exports.BasketItemAdded = BasketItemAdded
exports.Uncache = Uncache
exports.LocalDatabaseEvents = LocalDatabaseEvents
exports.LocalDatabaseUpdate = LocalDatabaseUpdate
exports.LocalUpdateTable = LocalUpdateTable
exports.RecordUpdate = RecordUpdate
exports.Table = Table
exports.AddRow = AddRow
exports.DeleteRow = DeleteRow
exports.TableUpdate = TableUpdate
exports.TableFlushCache = TableFlushCache
exports.LocalDatabasePut = LocalDatabasePut
exports.LocalDatabaseGet = LocalDatabaseGet
exports.LocalDatabaseWillGet = LocalDatabaseWillGet
exports.EnsureClient = EnsureClient
exports.Authorization = Authorization
exports.VerifiedUser = VerifiedUser
exports.ValidateToken = ValidateToken
exports.DecorateUser = DecorateUser
exports.RecycleItem = RecycleItem
exports.RestoreItem = RestoreItem

exports.ValidateWebhookRegistration = ValidateWebhookRegistration
exports.WebhookRegistered = WebhookRegistered
exports.SendWebhooks = SendWebhooks

exports.StartDataLoad = createEvent("StartDataLoad")
exports.DataLoaded = createEvent("DataLoaded")
exports.FlushAllCache = createEvent("FlushAllCache", { isAsync: true })
exports.FlushGraphQLCache = createEvent("FlushGraphQLCache", { isAsync: true })
exports.ReadGroup = createEvent("ReadGroup", { isAsync: true })
exports.ReadTree = createEvent("ReadTree", { isAsync: true })
exports.recycle = RecycleItem.raiseAsync
exports.ReInitialiseServer = createEvent("ReInitialiseServer", { isAsync: true })
exports.WriteGroup = createEvent("WriteGroup", { isAsync: true })
exports.GetDemands = createEvent("GetDemands", { extract: (v) => v[0].demands })
exports.AdoptClient = createEvent("AdoptClient")
exports.InvalidLink = createEvent("InvalidLink")
exports.TestTrigger = createEvent("TestTrigger")

exports.CompleteAction = createEvent("CompleteAction", { extract: (v) => v[0].isComplete, isAsync: true })

exports.PermissionsUpdated = createEvent("PermissionsUpdated")
exports.LicencePurchased = createEvent("LicencePurchased")

exports.ConfigureDemands = createEvent("ConfigureDemands", {
    extract: (v) => v[0].demands,
    isAsync: true,
    extended: true,
})
exports.ConfigureShareLinkDemands = createEvent("ConfigureShareLinkDemands", {
    extract: (v) => v[0].demands,
    isAsync: true,
    extended: true,
})

exports.CompletedAction = createEvent("CompleteAction", { isAsync: true })
exports.TreeUpdated = createEvent("TreeUpdated")

exports.TreeCacheKeys = createEvent("TreeCacheKeys", { extract: (v) => v[0].keys })

exports.ServerReady = createEvent("ServerReady")

// Auditing
exports.AuditEvent = createEvent("AuditEvent")
exports.createAudit = function createAudit({ shortDescription, longDescription, type, referenceId, payload }) {
    return exports.AuditEvent.raiseAsync({ shortDescription, longDescription, type, referenceId, payload })
}

exports.PredicateSchedules = createEvent("PredicateSchedules", { extract: (v) => v[0].handled, isAsync: true })

exports.ShowSnackbar = createEvent("ShowSnackbar")
exports.showSnackBarToInstance = (message, options = { variant: "info", autoHideDuration: 5000 }) =>
    exports.ShowSnackbar.raiseForCurrentInstance(message, options)
exports.DatabaseIndex = createEvent("DatabaseIndex")
exports.SwitchUserClient = createEvent("SwitchUserClient")
exports.SettingUpdated = createEvent("SettingUpdated", { isAsync: true })

exports.ShareLinkValidateRule = createEvent("ShareLinkValidateRule", { isAsync: true, extract: (v) => v[0].valid })

exports.SharingLinkUpdated = createEvent("SharingLinkUpdated")

exports.RefreshTreeRoots = createEvent("RefreshTreeRoots")

exports.AuthCode = createEvent("AuthCode")
exports.UserCreated = createEvent("UserCreated")
exports.HighMemoryUsage = createEvent("HighMemoryUsage")
exports.CreateUser = createEvent("CreateUser")
exports.CanUserWrite = createEvent("CanUserWrite")
exports.PreventUserWrite = createEvent("PreventUserWrite")
exports.EnsurePersonalAccount = createEvent("EnsurePersonalAccount")
exports.UserLoggedOut = createEvent("UserLoggedOut")
exports.ClientDemands = createEvent("ClientDemands", {
    extract: (v) => v[0].demands,
    expose: "get",
    parameters: { demands: [] },
})

exports.DemandAudit = createEvent("DemandAudit", {
    extract: (v) => v[0].demands,
    expose: "verify",
})

exports.ApplySharingFilter = createEvent("ApplySharingFilter")
exports.ValidateDraft = createEvent("ValidateDraft", {
    extract: (v) => v[0].draft,
    expose: "validate",
    parameters: (v) => ({ draft: v[0] }),
})

exports.ValidateAction = createEvent("ValidateAction", {
    extract: (v) => v[0].include,
    parameters: ([action]) => ({ include: true, action }),
    expose: "validate",
    isAsync: true,
})

exports.Invalidate = createEvent("Invalidate")

exports.FilterTask = createEvent("FilterTask", {
    extract: (v) => v[0].include,
    expose: "filter",
    parameters: ([task]) => ({ include: true, task }),
})

exports.HelpUpdated = createEvent("HelpUpdated")
exports.RemovedNameSpace = createEvent("RemovedNameSpace")
exports.SetNamespaceForUser = createEvent("SetNamespaceForUser")
exports.GetNamespaceForUser = createEvent("GetNamespaceForUser")

exports.JobCompleted = createEvent("JobCompleted")
exports.JobFailed = createEvent("JobFailed")

exports.GetMockLicenses = createEvent("GetMockLicenses")

exports.AIScheduleList = createEvent("AIScheduleList")
exports.AIResponse = createEvent("AIResponse")
exports.GetAvailableDemands = createEvent("GetAvailableDemands")

exports.Scheduler = createEvent("Scheduler")

exports.CustomSkillDeleted = createEvent("CustomSkillDeleted", { isAsync: true })

exports.Refresher = createEvent("Refresher")
exports.clientRefresh = function clientRefresh(name) {
    exports.Refresher.raiseForCurrentInstance(name).catch(console.error)
}

exports.WatchChanged = createEvent("WatchChanged")
exports.OverridePermission = createEvent("OverridePermission", { isAsync: true, extract: (v) => v[0].override })

exports.IsLicensed = createEvent("IsLicensed", {
    isAsync: true,
    extract: (v) => v[0].isLicensed,
    expose: "check",
    parameters: ([schedule]) => ({ isLicensed: false, schedule }),
})
exports.XmlFormatTask = createEvent("XmlFormatTask")
exports.XmlScheduleGroups = createEvent("XmlScheduleGroups")

exports.ServerOnline = createEvent("ServerOnline")
exports.RegisterMapping = createEvent("RegisterMapping")

exports.BusySystem = createEvent("BusySystem")
exports.EndSession = createEvent("EndSession")

exports.systemBusy = function (message) {
    if (typeof message === "string") {
        exports.BusySystem.raiseForCurrentInstance({ id: "system_busy", message, running: !!message })
    } else {
        exports.BusySystem.raiseForCurrentInstance({ id: "system_busy", ...message })
    }
}
