import { Button, InputAdornment, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { Bound } from "lib/@components/binding/Bound"
import { BoundTextField } from "lib/@components/binding/bound-components"
import { useRefresh } from "lib/@hooks/useRefresh"
import { useYupContext } from "lib/@components/binding/use-yup-wrapper"
import Iconify from "minimals-template/components/Iconify"
import { useReferenceState } from "lib/@hooks/use-reference"

export function AskForEmail() {
    const [state] = useState({ email: "" })
    const refresh = useRefresh()
    const [yupContext, validate] = useYupContext()
    const [, setEmail] = useReferenceState("email", "")
    return (
        <Bound target={state} refresh={refresh} yupContext={yupContext}>
            <center>
                <Stack spacing={2}>
                    <Typography variant="h3" gutterBottom>
                        Enter your email address
                    </Typography>
                    <Typography gutterBottom>
                        To continue searching, we would like to get your work email address.
                    </Typography>
                    <div />
                    <BoundTextField
                        autoFocus
                        autoComplete="email"
                        onChangeProp="onInput"
                        onKeyDown={handleEnter}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Iconify
                                        icon="material-symbols:alternate-email-rounded"
                                        sx={{ color: "text.disabled", width: 20, height: 20 }}
                                    />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button
                                        onClick={storeEmail}
                                        variant="contained"
                                        color="primary"
                                        disabled={!validate()}
                                    >
                                        Ok
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                        yup="string.email.required"
                        field="email"
                        sideEffects
                    />
                </Stack>
            </center>
        </Bound>
    )

    function handleEnter({ key }) {
        if (key === "Enter" && validate()) {
            storeEmail()
        }
    }

    function storeEmail() {
        setEmail(state.email)
    }
}
