import { useRef, useState } from "react"
import { AIResponse } from "event-definitions"
import { Alert, Box, Button, Container, InputAdornment, Stack, TextField, Typography } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import { setFromEvent } from "lib/setFromEvent"
import Iconify from "minimals-template/components/Iconify"
import { ProgressAI } from "routes/plugins/schedule-ai/progress-a-i"
import { Answer } from "routes/plugins/schedule-ai/answer"
import { askMarketingQuestion } from "routes/plugins/marketing-ai-search/controller/ask-marketing-question"
import { useReferenceState } from "lib/@hooks/use-reference"
import { If } from "lib/@components/switch"
import { Else } from "lib/@components/else"
import { AskForEmail } from "routes/plugins/marketing-ai-search/ask-for-email"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { convertToPdf } from "lib/convert-to-pdf"

export function SearchForm() {
    const [search, setSearch] = useState("")
    const [showEmail, setShowEmail] = useState(false)
    const [count, setCount] = useReferenceState("count", 0)
    const [dates, setDates] = useReferenceState("_dto", [])
    const [email] = useReferenceState("email", "")
    const [searched, setSearched] = useState("")
    const [response, setResponse] = useReferenceState("lastResponse", "")
    const output = useRef()
    const [busy, setBusy] = useState(false)
    const { recaptcha } = useBoundContext()
    const tooMany = dates.filter((d) => Date.now() - d < 1000 * 60 * 60 * 24 * 15).length > 5
    AIResponse.useEvent(setResponse)
    return (
        <Container maxWidth="md">
            <Stack spacing={3}>
                <If truthy={showEmail && !email}>
                    <AskForEmail />
                    <Else>
                        <Typography variant="h4">SFG20 Schedule Search</Typography>
                        <Typography variant="body">
                            Enter the specific brand and model number of an asset you wish to maintain, or the type of
                            asset you wish to maintain, such as a condensing boiler. You can use acronyms and
                            abbreviations. Search results are not exhaustive, especially for less-specific questions.
                        </Typography>
                        <ListItemBox spacing={1}>
                            <Box flex={1}>
                                <If truthy={tooMany}>
                                    <If truthy={busy}>
                                        <Alert severity="info">
                                            You have run out of searches for now,{" "}
                                            <strong>the current search will complete</strong>, but you cannot perform
                                            any more. Please contact SFG20 on +441768 807490 or by email at
                                            info@sfg20.co.uk to discuss your requirements.
                                        </Alert>

                                        <Else>
                                            <Alert severity="info">
                                                You have run out of searches for now, please contact SFG20 on +441768
                                                807490 or by email at info@sfg20.co.uk to discuss your requirements.
                                            </Alert>
                                        </Else>
                                    </If>
                                    <Else>
                                        <TextField
                                            onKeyDown={handleEnter}
                                            variant="outlined"
                                            value={search}
                                            autoComplete="off"
                                            type="text"
                                            name="search"
                                            onChange={setFromEvent(setSearch)}
                                            sx={{ width: 1 }}
                                            autoFocus
                                            label="What asset type would you like to maintain?"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Iconify
                                                            icon="icon-park-twotone:search"
                                                            sx={{ color: "text.disabled", width: 20, height: 20 }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Button
                                                            disabled={
                                                                busy || !search.trim() || search.trim().length < 3
                                                            }
                                                            color="secondary"
                                                            onClick={clear}
                                                        >
                                                            <Iconify icon="ic:round-clear" />
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            disabled={busy || !search.trim() || search.length < 3}
                                                            onClick={ask}
                                                            size="small"
                                                        >
                                                            Search...
                                                        </Button>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Else>
                                </If>
                            </Box>
                        </ListItemBox>
                        {!!busy && !response && <ProgressAI />}
                        {!!response && <Answer response={response} adapt={false} ref={output} />}
                        {!!response && !busy && !response.startsWith("Unfortunately") && (
                            <ListItemBox>
                                <Box flex={1} />
                                <Button
                                    onClick={downloadResponse}
                                    startIcon={<Iconify icon="iconamoon:cloud-download-duotone" />}
                                >
                                    Download
                                </Button>
                            </ListItemBox>
                        )}
                        <Box mt={10} />
                    </Else>
                </If>
            </Stack>
        </Container>
    )

    function clear() {
        setSearch("")
        setResponse("")
    }

    function handleEnter({ key }) {
        if (key === "Enter") {
            ask()
        }
    }

    function downloadResponse() {
        convertToPdf(
            `<style>
  ul {
    margin-top: 15px;
    margin-bottom: 15px;
    list-style: none; /* Disable default bullets */
    padding-left: 0;
  }
  h2, h3, h1, h4 {
    margin-bottom: 20px;
  }
  li {
    margin-bottom: 10px;
    padding-left: 30px; /* Space for the custom bullet */
    position: relative;
  }
   p, h1, h2, h3, li, div {
    page-break-inside: avoid;
    break-inside: avoid;
  }
  li::before {
    content: "⦿"; /* Unicode bullet */
    position: absolute;
    left: 0;         /* Adjust left value as needed */
    top: 0;
    font-size: 1.2em;  /* Increase bullet size */
    line-height: 1;
    color: inherit;
  }
  li li::before {
    content: "○"; /* Unicode bullet */
  }
            </style>
            <div style="margin-bottom: 30px">
            <img src="https://www.sfg20.co.uk/hubfs/SFG20_Web_Theme_Nov2023/PRIMARY%20LOGO%20(1).svg" alt="PRIMARY LOGO Main" loading="lazy" width="145" height="35" style="max-width: 100%; height: auto;">
            </div>
            <div>
            ${response}
            </div>
            <div style="margin-top: 50px; color: #38939b;">
            Contact SFG20 on +441768 807490 or by email at info@sfg20.co.uk
            </div>
            <div style="height: 20px"/>`,
            `${searched}.pdf`
        )
    }

    async function ask() {
        setDates((dates) => [...dates, Date.now()])
        if (count > 0 && !email) {
            setShowEmail(true)
            return
        }
        setSearched(search)
        setCount((c) => c + 1)
        try {
            setBusy(true)
            setResponse("")
            const response = await askMarketingQuestion(search, recaptcha, email)
            setResponse(response)
        } catch (e) {
            console.error(e)
            setResponse("Error generating response", e.message)
        } finally {
            setSearch("")
            setBusy(false)
        }
    }
}
