// import { registerTab } from "lib/@components/tabs"
import { Lazy } from "lib/make-lazy"
import { appRoute } from "routes/app/home.runtime"

appRoute.register(
    "sfg20admin/client-migration/:id",

    <Lazy
        importer={() => import("./client-migration-detail")}
        extract={"ClientMigrationDetail"}
        demands={"admin-clientmigration"}
    />
)
