import { forwardRef, useCallback, useMemo } from "react"
import { Box, useTheme } from "@mui/material"
import { useTreeItemIndex } from "routes/schedule/lib/useTreeItemIndex"
import { idIndex } from "routes/schedule/lib/getTreeItemIndex"
import { useBoundValue } from "lib/@components/binding/use-bound-value"

function cleanAndDisplayHTML(htmlString) {
    const text = htmlString
    const idx = text.lastIndexOf("<")
    const next = text.lastIndexOf(">")
    if (next < idx) {
        return text.slice(0, idx - 1)
    }
    const s_idx = text.lastIndexOf("[")
    const s_next = text.lastIndexOf("]")
    if (s_next < s_idx) {
        return text.slice(0, s_idx - 1)
    }

    return text
}

export const Answer = forwardRef(function Answer({ response, adapt = true }, ref) {
    const theme = useTheme()
    const index = useTreeItemIndex(true)
    const [, setSchedules] = useBoundValue("schedules")
    const _adaptContent = useCallback(adaptContent, [index, setSchedules])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const html = useMemo(
        () => (adapt ? _adaptContent(cleanAndDisplayHTML(response)) : cleanAndDisplayHTML(response)),
        [response, adapt, _adaptContent]
    )

    return (
        <Box
            sx={{
                borderRadius: 1,
                border: "1px solid " + theme.palette.action.active,
                p: 3,
                "& a": {
                    display: "inline-block",
                    borderRadius: 0.5,
                    background: theme.palette.action.hover,
                    px: 1,
                    py: 0.2,
                    mx: 0.2,
                    whiteSpace: "nowrap",
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                },
                "& a[data-unlicensed]": {
                    color: theme.palette.secondary.main,
                },
                "& h2": { mb: 2, mt: 1 },
                "& *": {
                    userSelect: "text",
                },
                "& ul": { my: 2, ml: 4, maxWidth: 650 },
                "& p": {
                    mb: 2,
                },
                "& li": {
                    mb: 1,
                },
            }}
        >
            <Box ref={ref} dangerouslySetInnerHTML={{ __html: html }} />
        </Box>
    )

    function adaptContent(content) {
        const regex = /\[SFG20:(\d+)(?:, SFG20:(\d+))*\]/g

        const schedules = []

        let result = content.replace(regex, (match, ...numbers) => {
            // Filter out only the number captures, excluding undefined and the full match
            const validNumbers = numbers.slice(0, -2).filter(Boolean)

            return validNumbers
                .map((number) => {
                    const [item] = index[idIndex]?.[`${number}:sfg20/schedule`] ?? []

                    // Define the base URL for the anchor tag
                    const baseUrl = `/app/schedules/${number}`
                    if (item) {
                        schedules.push(item)
                    }
                    // Depending on the item's properties, construct the anchor tag
                    if (!item) {
                        return `<a data-unlicensed="true" onclick="localNav('${baseUrl}')">Details</a>`
                    } else if (item.notLicensed) {
                        return `<a data-unlicensed="true" onclick="localNav('${baseUrl}')">${item.code}</a>`
                    } else {
                        return `<a onclick="localNav('${baseUrl}')">${item.code}</a>`
                    }
                })
                .join(", ") // Join multiple SFG20 values with a comma and space
        })

        setSchedules(schedules)
        return result
    }
})
